//for FireFox
@font-face {
	font-family: 'FontAwesome';
	src: local('FontAwesome');
}

@font-face {
	font-family: 'Open_Sans_Light';
	src: url('fonts/Open_Sans/OpenSans-Light.ttf');
}

@font-face {
	font-family: 'Open_Sans_Regular';
	src: url('fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'Open_Sans_Semi-bold';
	src: url('fonts/Open_Sans/OpenSans-Semibold.ttf');
}

@font-face {
	font-family: 'Larsseit-Bold';
	src: url('fonts/Larsseit/Larsseit-Bold.otf');
}

/**
Create24
**/

@font-face {
	font-family: 'Amatic SC';
	src: url('fonts/Amatic_SC/AmaticSC-Regular.ttf');
}

@font-face {
	font-family: 'Architects Daughter';
	src: url('fonts/Architects_Daughter/ArchitectsDaughter-Regular.ttf');
}

@font-face {
	font-family: 'Archivo Black';
	src: url('fonts/Archivo_Black/ArchivoBlack-Regular.ttf');
}

@font-face {
	font-family: 'Arsenal';
	src: url('fonts/Arsenal/Arsenal-Regular.ttf');
}

@font-face {
	font-family: 'Arvo';
	src: url('fonts/Arvo/Arvo-Regular.ttf');
}

@font-face {
	font-family: 'Cabin';
	src: url('fonts/Cabin/Cabin.ttf');
}

@font-face {
	font-family: 'Calibri';
	src: url('fonts/Calibri/Calibri Regular.ttf');
}

@font-face {
	font-family: 'Cinzel';
	src: url('fonts/Cinzel/Cinzel.ttf');
}

@font-face {
	font-family: 'Consolas';
	src: url('fonts/Consolas/Consolas.ttf');
}

@font-face {
	font-family: 'Cormorant Infant';
	src: url('fonts/Cormorant_Infant/CormorantInfant-Regular.ttf');
}

@font-face {
	font-family: 'Crimson Text';
	src: url('fonts/Crimson_Text/CrimsonText-Regular.ttf');
}

@font-face {
	font-family: 'Cutive';
	src: url('fonts/Cutive/Cutive-Regular.ttf');
}

@font-face {
	font-family: 'Franklin Gothic';
	src: url('fonts/Franklin_Gothic/FranklinGothic.woff');
}

@font-face {
	font-family: 'Fondamento';
	src: url('fonts/Fondamento/Fondamento-Regular.ttf');
}

@font-face {
	font-family: 'Great Vibes';
	src: url('fonts/Great_Vibes/GreatVibes-Regular.ttf');
}

@font-face {
	font-family: 'Ibarra Real Nova';
	src: url('fonts/Ibarra_Real_Nova/IbarraRealNova-VariableFont_wght.ttf');
}

@font-face {
	font-family: 'Jura';
	src: url('fonts/Jura/Jura-VariableFont_wght.ttf');
}

@font-face {
	font-family: 'Klinic Slab Regular';
	src: url('fonts/Klinic_Slab/KlinicSlabRegular.otf');
}

@font-face {
	font-family: 'Klinic Slab Semibold';
	src: url('fonts/Klinic_Slab/KlinicSlabSemibold.otf');
}

@font-face {
	font-family: 'Klinic Slab Bold';
	src: url('fonts/Klinic_Slab/KlinicSlabBold.otf');
}

@font-face {
	font-family: 'Lato';
	src: url('fonts/Lato/Lato-Regular.ttf');
}

@font-face {
	font-family: 'Life Savers';
	src: url('fonts/Life_Savers/LifeSavers-Regular.ttf');
}

@font-face {
	font-family: 'Lora';
	src: url('fonts/Lora/Lora-VariableFont_wght.ttf');
}

@font-face {
	font-family: 'Lucida Console';
	src: url('fonts/Lucida_Console/Lucida_Console.ttf');
}

@font-face {
	font-family: 'Merienda';
	src: url('fonts/Merienda/Merienda-Regular.ttf');
}

@font-face {
	font-family: 'Merriweather';
	src: url('fonts/Merriweather/Merriweather-Regular.ttf');
}

@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
	font-family: 'Mulish';
	src: url('fonts/Mulish/Mulish-Regular.ttf');
}

@font-face {
	font-family: 'Newsreader';
	src: url('fonts/Newsreader/Newsreader-Regular.ttf');
}

@font-face {
	font-family: 'Open Sans Condensed';
	src: url('fonts/Open_Sans_Condensed/OpenSansCondensed-Light.ttf');
}

@font-face {
	font-family: 'Oswald';
	src: url('fonts/Oswald/Oswald-Regular.ttf');
}

@font-face {
	font-family: 'Pacifico';
	src: url('fonts/Pacifico/Pacifico-Regular.ttf');
}

@font-face {
	font-family: 'Permanent Marker';
	src: url('fonts/Permanent_Marker/PermanentMarker-Regular.ttf');
}

@font-face {
	font-family: 'Playball';
	src: url('fonts/Playball/Playball-Regular.ttf');
}

@font-face {
	font-family: 'Playfair Display';
	src: url('fonts/Playfair_Display/PlayfairDisplay-Regular.ttf');
}

@font-face {
	font-family: 'Poppins';
	src: url('fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
	font-family: 'Quicksand';
	src: url('fonts/Quicksand/Quicksand-Regular.ttf');
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf');
}

@font-face {
	font-family: 'Roboto Mono';
	src: url('fonts/Roboto_Mono/RobotoMono.ttf');
}

@font-face {
	font-family: 'Roboto Slab';
	src: url('fonts/Roboto_Slab/RobotoSlab-Regular.ttf');
}

@font-face {
	font-family: 'Rosario';
	src: url('fonts/Rosario/Rosario-Regular.ttf');
}

@font-face {
	font-family: 'Rubik';
	src: url('fonts/Rubik/Rubik-Regular.ttf');
}

@font-face {
	font-family: 'Sancreek';
	src: url('fonts/Sancreek/Sancreek-Regular.ttf');
}

@font-face {
	font-family: 'Sinkin Sans Regular';
	src: url('fonts/Sinkin_Sans/SinkinSans-Regular.otf');
}

@font-face {
	font-family: 'Sinkin Sans Semibold';
	src: url('fonts/Sinkin_Sans/SinkinSans-Semibold.otf');
}

@font-face {
	font-family: 'Sinkin Sans Bold';
	src: url('fonts/Sinkin_Sans/SinkinSans-Bold.otf');
}

@font-face {
	font-family: 'Special Elite';
	src: url('fonts/Special_Elite/SpecialElite-Regular.ttf');
}

@font-face {
	font-family: 'Uncial Antiqua';
	src: url('fonts/Uncial_Antiqua/UncialAntiqua-Regular.ttf');
}

@font-face {
	font-family: 'Yellowtail';
	src: url('fonts/Yellowtail/Yellowtail-Regular.ttf');
}
