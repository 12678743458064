/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

.tippy-box {
	text-align: center;
}

/*-------------
Color Variables
--------------*/

$blue: #2980b9;
$blueLight: lighten($blue, 16%);
$blueLighter: lighten($blue, 40%);
$blueDarker: darken($blue, 6%);
$blueDarkest: darken($blue, 16%);

//https://codepen.io/Cthulahoop/pen/boaFg?depth=everything&order=popularity&page=4&q=css+radial+gradient&show_forks=false
$blueLighterRadial: lighten($blue, 5%);
$blueDarkerRadial: darken($blue, 3%);

$card-border-gray: #d4d4d5;

$dark: #23282d;
$darkLighter: lighten($dark, 6%);
$facebookBlue: #3b5998;

$gray: #e6e8e9;
$grayDark: darken($gray, 6%);
$grayDarker: darken($gray, 16%);
$grayDarkest: #8b8c8c;

$green: #16a085;
$greenLighter: lighten($green, 22%);
$greenDarker: darken($green, 6%);
$greenDarkest: darken($green, 16%);

$greenLighterRadial: lighten($green, 5%);
$greenDarkerRadial: darken($green, 3%);

$instagramRed: #d91642;

$light-gray: #f6f8f8;
$lightGrayDarker: darken($light-gray, 8%);

$limeGreen: #2ecc71;
$limeGreenDarker: darken($limeGreen, 6%);

$orange: #f9690e;
$orangeDarker: darken($orange, 6%);
$orangeDarkest: darken($orange, 16%);

$pink: #d2527f;
$pinkDarker: darken($pink, 6%);
$pinkDarkest: darken($pink, 22%);

$pinterest-red: #bd081c;
$purple: #9b59b6;
$purpleDarker: darken($purple, 6%);
$purpleDarkest: darken($purple, 16%);
$purpleLighter: lighten($purple, 42%);

$purpleLighterRadial: lighten($purple, 5%);
$purpleDarkerRadial: darken($purple, 3%);

$darkPurple: #674172;

$red: #c3161e;
$redLight: lighten($red, 20%);
$redLighter: lighten($red, 40%);
$redDarker: darken($red, 6%);
$redDarkest: darken($red, 16%);

$redLighterRadial: lighten($red, 5%);
$redDarkerRadial: darken($red, 3%);

$turquoise: #1abc9c;
$turquoiseDarker: darken($turquoise, 6%);

$yellow: #fed36e;
$yellowLight: lighten($yellow, 10%);
$yellowDarker: darken($yellow, 6%);
$yellowDarkest: darken($yellow, 16%);

$white: #fff;
$whiteDarker: darken($white, 6%);

$deliveryQueueBrown: #b9958d;
$deliveryQueueYellow: #fef6c9;
$deliveryQueueGreen: #a8c7b2;
$deliveryQueueRed: #ea7185;

$clientHealthRed: rgb(255, 99, 132);
$clientHealthOrange: #ffa347;
$clientHealthGreen: #4bc0c0;
$clientHealthPurple: #9a18c9;
$clientHealthBlue: #0d5eab;

.client-health-purple {
	background-color: $clientHealthPurple;
}

.client-health-blue {
	background-color: $clientHealthBlue;
}

.client-health-orange {
	background-color: $clientHealthOrange;
}
.client-health-orange-border {
	border: 4px solid $clientHealthOrange;
}

.client-health-red {
	background-color: $clientHealthRed;
}
.client-health-red-border {
	border: 4px solid $clientHealthRed;
}

.client-health-red-text {
	color: $clientHealthRed;
}

.client-health-green {
	background-color: $clientHealthGreen;
}

.client-health-green-border {
	border: 4px solid $clientHealthGreen;
}

.delivery-queue-purple-bg {
	background-color: $purple !important;
}

.delivery-queue-brown-bg {
	background-color: $deliveryQueueBrown !important;
}

.delivery-queue-yellow-bg {
	background-color: $deliveryQueueYellow !important;
}

.delivery-queue-green-bg {
	background-color: $deliveryQueueGreen !important;
}

.delivery-queue-red-bg {
	background-color: $deliveryQueueRed !important;
}

/*--------------------
  Global Styles
---------------------*/

//using these global selectors for tables
//that loop data in a div outside the component selector.
//tables that loop within the component use the .table-row
//class name and those styles are in the table.scss file
.gray-stripe:nth-child(even) .t-row {
	background-color: $white;
}

.gray-stripe:nth-child(odd) .t-row {
	background-color: $gray;
}

.t-row {
	border-bottom: 1px solid $card-border-gray;
	border-left: 1px solid $card-border-gray;
	border-right: 1px solid $card-border-gray;
}

html {
	position: relative;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

img {
	display: inline !important;
}

svg {
	display: initial;
}

video {
	display: inline-block;
}

.table td {
	border: inherit !important;
}

.table tbody tr:nth-child(odd) {
	background-color: $light-gray !important;
}

.regular {
	font-family: 'Open_Sans_Regular';
}

.semi-bold {
	font-family: 'Open_Sans_Semi-bold';
}

.light {
	font-family: 'Open_Sans_Light', sans-serif;
}

body {
	background-color: $light-gray;
	font-family: 'Open_Sans_Light', sans-serif;
	font-weight: 400;
	min-height: 100%;
	overflow-x: scroll;
}

.spinnerIndex {
	height: 80px;
	width: 80px;
	border: 3px solid #d0d0d0;
	border-top: 3px solid #8b8c8c;
	border-radius: 100%;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;

	animation: spin 0.6s infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.fa-square {
	transform: scale(1, 1.6);
}

.pointer {
	cursor: pointer;
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.cursor-text {
	cursor: text;
}

.cursor-move {
	cursor: move;
}

.cursor-context-menu {
	cursor: context-menu;
}

.cursor-auto {
	cursor: auto;
}

a {
	cursor: pointer;
}

h1,
h2,
h3,
h4 {
	margin: 0;
	font-weight: 300;
	user-select: text;
}

h1 {
	font-size: 1.75rem;
}

h2 {
	font-size: 1.5rem;
}

h3 {
	font-size: 1.17rem;
}

h4 {
	font-size: 0.938rem;
}

hr {
	border-color: $card-border-gray;
}

body a:hover {
	text-decoration: none;
	color: $blue;
}

input,
textarea {
	border: 0.125rem solid $grayDark;
	background: $grayDark;
	border-radius: 0.25rem;
	background-clip: padding-box;
	transition: all 0.2s ease;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

input[type='text'] {
	height: 2.5rem;
}

input[type='text'],
textarea {
	width: 100%;
}

input[readonly],
textarea[readonly] {
	font-style: italic;
	color: #616161;
}

textarea {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

input:focus,
textarea:focus {
	outline: none;
	background-color: $light-gray;
}

p {
	margin: 0;
}

.ellipsis {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.scrollable {
	overflow-y: scroll;
	overflow-x: hidden;
	// overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}

.setOpacityLow {
	opacity: 0.2;
}

.setOpacity {
	opacity: 1;
	transition: all 0.2s;
}

.setOpacity:hover {
	opacity: 0.8;
}

.underline {
	text-decoration: underline;
}

ul {
	padding-left: 12px;
}

ul li {
	list-style: none;
}

/*-------------
Helpers
--------------*/

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.block {
	display: block;
}

.in-block {
	display: inline-block;
}

.right {
	float: right;
}

.left {
	float: left;
}

.center {
	text-align: center;
}

/*https://css-tricks.com/centering-css-complete-guide/ */
.center-unknown-height-width {
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

.container {
	width: 98%;
	margin: 0 auto;
}

.col-centered {
	margin: 0 auto;
}

.box-shadow-bottom {
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1);
}

.box-shadow-bottom-gray {
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.3);
}

.shadow {
	// box-shadow: 0 0.125em 0.313em 0 rgba(0, 0, 0, 0.16), 0 0.125em 0.625em 0 rgba(0, 0, 0, 0.12);
	// box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149); //taken from the gmail reply box
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.box-shadow {
	box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 27px -13px;
}

.shadow-wide {
	box-shadow: 0 0.125em 4.313em 0 rgba(0, 0, 0, 0.36), 0 0.125em 0.625em 0 rgba(0, 0, 0, 0.12);
}

.checkbox {
	border: 0.125rem solid $lightGrayDarker;
}

.setWidth100 {
	width: 100%;
}

.setWidth75 {
	width: 75%;
}

.setWidth50 {
	width: 50%;
}

.setWidth49 {
	width: 49%;
}

.setWidth25 {
	width: 25%;
}

.setHeight100 {
	height: 100%;
}

.setHeight50 {
	height: 50%;
}

.setOverflow {
	overflow: hidden;
}

.card-border {
	box-shadow: 0 0.063em 0.188em 0 $card-border-gray, 0 0 0 0.063em #d4d4d5;
}

.text-shadow {
	text-shadow: 1px 1px #23282d;
}

.border-radius {
	border-radius: 0.25rem; //4px
}

.border-radius-md {
	border-radius: 0.375em;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.z {
	z-index: 99;
}

$measurements: (
	neg212: -13.25rem,
	neg184: -11.5rem,
	neg59: -3.688rem,
	neg54: -3.375rem,
	neg45: -2.813rem,
	neg41: -2.563rem,
	neg39: -2.438rem,
	neg36: -2.25rem,
	neg35: -2.188rem,
	neg33: -2.357rem,
	neg32: -2rem,
	neg30: -1.875rem,
	neg27: -1.688rem,
	neg26: -1.625rem,
	neg24: -1.5rem,
	neg23: -1.438rem,
	neg22: -1.375rem,
	neg21: -1.313rem,
	neg20: -1.25rem,
	neg18: -1.125rem,
	neg17: -1.063rem,
	neg16: -1rem,
	neg15: -0.938rem,
	neg14: -0.875rem,
	neg12: -0.75rem,
	neg11: -0.688rem,
	neg10: -0.625rem,
	neg9: -0.563rem,
	neg8: -0.5rem,
	neg7: -0.438rem,
	neg6: -0.375rem,
	neg5: -0.313rem,
	neg4: -0.25rem,
	neg3: -0.188rem,
	neg2: -0.125rem,
	neg1: -0.63rem,
	0: 0rem,
	1: 0.063rem,
	2: 0.125rem,
	3: 0.188rem,
	4: 0.25rem,
	5: 0.313rem,
	6: 0.375rem,
	7: 0.438rem,
	8: 0.5rem,
	9: 0.563rem,
	10: 0.625rem,
	11: 0.688rem,
	12: 0.75rem,
	13: 0.813rem,
	14: 0.875rem,
	15: 0.938rem,
	16: 1rem,
	17: 1.063rem,
	18: 1.125rem,
	19: 1.188rem,
	20: 1.25rem,
	21: 1.313rem,
	22: 1.375rem,
	23: 1.438rem,
	24: 1.5rem,
	25: 1.563rem,
	26: 1.625rem,
	27: 1.688rem,
	28: 1.75rem,
	29: 2.071rem,
	30: 1.875rem,
	31: 1.938rem,
	32: 2rem,
	33: 2.063rem,
	34: 2.125rem,
	35: 2.188rem,
	36: 2.25rem,
	37: 2.313rem,
	38: 2.375rem,
	39: 2.438rem,
	40: 2.5rem,
	41: 2.563rem,
	42: 2.625rem,
	43: 2.688rem,
	44: 2.75rem,
	45: 2.813rem,
	46: 2.875rem,
	47: 2.938rem,
	48: 3rem,
	49: 3.063rem,
	50: 3.125rem,
	51: 3.188rem,
	52: 3.25rem,
	53: 3.313rem,
	54: 3.375rem,
	55: 3.438rem,
	56: 3.5rem,
	57: 3.563rem,
	59: 3.688rem,
	60: 3.75rem,
	62: 3.875rem,
	63: 3.938rem,
	64: 4rem,
	66: 4.125rem,
	67: 4.188rem,
	68: 4.25rem,
	70: 4.375rem,
	71: 4.438rem,
	72: 4.5rem,
	74: 4.625rem,
	76: 4.75rem,
	78: 4.875rem,
	80: 5rem,
	82: 5.125rem,
	84: 5.25rem,
	85: 5.313rem,
	86: 5.375rem,
	88: 5.556rem,
	90: 5.625rem,
	96: 6rem,
	98: 6.125rem,
	100: 6.25rem,
	101: 6.313rem,
	102: 6.375rem,
	104: 6.5rem,
	105: 6.563rem,
	108: 6.75rem,
	109: 6.813rem,
	110: 6.875rem,
	112: 7.031rem,
	//112.5
	115: 7.188rem,
	116: 7.25rem,
	119: 7.438rem,
	120: 7.5rem,
	121: 7.563rem,
	124: 7.75rem,
	128: 8rem,
	129: 8.063rem,
	130: 8.125rem,
	133: 8.313rem,
	136: 8.5rem,
	138: 8.625rem,
	139: 8.688rem,
	140: 8.75rem,
	144: 9rem,
	145: 9.063rem,
	147: 9.188rem,
	150: 9.375rem,
	155: 9.688rem,
	157: 9.813rem,
	162: 10.125rem,
	166: 10.375rem,
	167: 10.438rem,
	172: 10.75rem,
	173: 10.813rem,
	174: 10.875rem,
	175: 10.94rem,
	178: 11.125rem,
	180: 11.25rem,
	182: 11.375rem,
	185: 11.56rem,
	187: 11.69rem,
	188: 11.75rem,
	192: 12rem,
	195: 12.188rem,
	200: 12.5rem,
	203: 12.688rem,
	204: 12.75rem,
	208: 13rem,
	210: 13.125rem,
	214: 13.375rem,
	215: 13.43rem,
	216: 13.5rem,
	217: 13.563rem,
	218: 13.625rem,
	220: 13.75rem,
	221: 13.813rem,
	222: 13.875rem,
	226: 14.125rem,
	229: 14.32rem,
	230: 14.375rem,
	236: 14.75rem,
	241: 15.063rem,
	242: 15.125rem,
	248: 15.5rem,
	250: 15.625rem,
	255: 15.938rem,
	256: 16rem,
	258: 16.125rem,
	262: 16.375rem,
	265: 16.563rem,
	270: 16.875rem,
	271: 16.938rem,
	274: 17.125rem,
	278: 17.375rem,
	280: 17.5rem,
	284: 17.75rem,
	288: 18rem,
	292: 18.25rem,
	299: 18.688rem,
	300: 18.75rem,
	310: 19.375rem,
	315: 19.688rem,
	322: 20.125rem,
	328: 20.5rem,
	338: 21.125rem,
	345: 21.563rem,
	360: 22.5rem,
	366: 22.875rem,
	370: 23.125rem,
	372: 23.25rem,
	375: 23.438rem,
	378: 23.625rem,
	384: 24rem,
	392: 24.5rem,
	400: 25rem,
	424: 26.5rem,
	426: 26.625rem,
	428: 26.75rem,
	440: 27.5rem,
	443: 27.688rem,
	444: 27.75rem,
	450: 28.125rem,
	472: 29.5rem,
	495: 30.938rem,
	497: 31.063rem,
	500: 31.25rem,
	508: 31.75rem,
	530: 33.125rem,
	550: 34.375rem,
	588: 36.75rem,
	600: 37.5rem,
	630: 39.375rem,
	660: 41.25rem,
	680: 42.5rem,
	711: 44.438rem,
	728: 45.5rem,
	782: 48.875rem,
	800: 50rem
);

$pixelMeasurements: (
	15: 15px,
	18: 18px,
	20: 20px,
	28: 28px,
	36: 36px,
	40: 40px,
	60: 60px,
	76: 76px,
	90: 90px
);

@each $size, $val in $pixelMeasurements {
	.htpx#{$size} {
		height: #{$val};
	}

	.wdthpx#{$size} {
		width: #{$val};
	}
}

/*loops through each measurement above, provides easy reusability*/

@each $name, $val in $measurements {
	/*Font-Size*/
	.ft-size#{$name} {
		font-size: #{$val} !important;
	}
	/*Margin*/
	.mgn#{$name} {
		margin: #{$val};
	}
	.mgn-top#{$name} {
		margin-top: #{$val};
	}
	.mgn-right#{$name} {
		margin-right: #{$val};
	}
	.mgn-bottom#{$name} {
		margin-bottom: #{$val};
	}
	.mgn-left#{$name} {
		margin-left: #{$val};
	}
	/*Padding*/
	.pd#{$name} {
		padding: #{$val};
	}
	.pd-top#{$name} {
		padding-top: #{$val};
	}
	.pd-right#{$name} {
		padding-right: #{$val};
	}
	.pd-bottom#{$name} {
		padding-bottom: #{$val};
	}
	.pd-left#{$name} {
		padding-left: #{$val};
	}
	/*Height*/
	.ht#{$name} {
		height: #{$val} !important;
	}

	/*Max Height*/
	.max-height#{$name} {
		max-height: #{$val};
	}
	/*Min Height*/
	.min-height#{$name} {
		min-height: #{$val};
	}
	/*Width*/
	.wdth#{$name} {
		width: #{$val};
	}
	/*Line-Height*/
	.ln-height#{$name} {
		line-height: #{$val};
	}
	/*Absolute Positioning*/
	.top#{$name} {
		top: #{$val};
	}
	.right#{$name} {
		right: #{$val};
	}
	.bottom#{$name} {
		bottom: #{$val};
	}
	.left#{$name} {
		left: #{$val};
	}
}

/*Text Color Classes*/

.blue-text {
	color: $blue !important;
}
.blue-text-light {
	color: $blueLight;
}

.blue-text-darkest {
	color: $blueDarkest;
}

.dark-text {
	color: $dark !important;
}

.gray-text {
	color: $gray !important;
}

.gray-text-dark {
	color: $grayDark;
}

.gray-text-darker {
	color: $grayDarker;
}

.gray-text-darkest {
	color: $grayDarkest;
}

.green-text {
	color: $green;
}
.green-text-darker {
	color: $greenDarker;
}

.lime-green-text {
	color: $limeGreen;
}

.orange-text {
	color: $orange;
}

.pink-text {
	color: $pink;
}

.purple-text {
	color: $purple;
}

.red-text {
	color: $red;
}
.red-text-light {
	color: $redLight;
}

.turquoise-text {
	color: $turquoise;
}

.saving-preloader-text {
	color: $grayDarker;
}

.white-text {
	color: $white !important;
}

.yellow-text {
	color: $yellow;
}

.yellow-text-darkest {
	color: $yellowDarkest;
}

/*Background Color Classes*/

.blue-bg {
	background-color: $blue !important;
}

.blue-bg-light {
	background-color: $blueLight;
}

.blue-bg-lighter {
	background-color: $blueLighter;
}

.blue-bg-darker {
	background-color: $blueDarker;
}

.blue-bg-darkest {
	background-color: $blueDarkest !important;
}

.blue-bg-radial {
	background: radial-gradient(ellipse farthest-corner, $blueLighterRadial 0%, $blueDarkerRadial 100%);
}

button.client-health-red {
	border: 0.063rem solid $clientHealthRed;
}

button.blue-bg {
	border: 0.063rem solid $blue;
}

button.dark-bg {
	border: 0.063rem solid $dark;
}

button.turquoise-bg {
	border: 0.063rem solid $turquoise !important;
}

button.blue-bg:hover,
.blue-bg a:hover {
	background-color: $blueDarker !important;
}
button.blue-bg-darker {
	border: 0.063rem solid $blueDarker;
}

button.blue-bg-darkest {
	border: 0.063rem solid $blueDarkest;
}

button.blue-bg-darkest:hover {
	background-color: $blueDarker !important;
}

button.green-bg {
	border: 0.063rem solid $green;
}

button.green-bg-darkest {
	border: 0.063rem solid $greenDarkest;
}

button.green-bg:hover,
.green-bg a:hover {
	background-color: $greenDarker !important;
}

button.orange-bg {
	border: 0.063rem solid $orange;
}

button.orange-bg:hover,
.orange-bg a:hover {
	background-color: $orangeDarker !important;
}

button.yellow-bg {
	border: 0.063rem solid $yellow;
}

button.yellow-bg-darkest {
	border: 0.063rem solid $yellowDarkest;
}

button.yellow-bg:hover,
.yellow-bg a:hover {
	background-color: $yellowDarker !important;
}

button.white-bg {
	border: 0.063rem solid $grayDarker;
}

button.white-bg:hover {
	background-color: $light-gray;
}

button.pink-bg {
	border: 0.063em solid $pink;
}

button.pink-bg-darkest {
	border: 0.063em solid $pinkDarkest;
}

button.pink-bg:hover {
	background-color: $pinkDarker;
}

button.limeGreen-bg {
	border: 0.063em solid $limeGreen;
}

button.limeGreen-bg:hover {
	background-color: $limeGreenDarker;
	border: 0.063em solid $limeGreenDarker;
}

button.purple-bg {
	border: 0.063em solid $purple;
}
button.purple-bg-darkest {
	border: 0.063em solid $purpleDarkest;
}

button.purple-bg:hover,
.purple-bg a:hover {
	background-color: $purpleDarker !important;
}

button.light-gray-bg {
	border: 0.063em solid $light-gray;
}

button.light-gray-bg:hover {
	background-color: $lightGrayDarker !important;
}

button.lt-gray-bg-darker {
	border: 0.063em solid $lightGrayDarker;
}

button.gray-bg {
	border: 0.063em solid $gray;
}

button.gray-bg:hover {
	background-color: $grayDark;
}

button.gray-bg-dark {
	border: 0.063em solid $grayDark;
}

button.gray-bg-dark:hover {
	background-color: $grayDarker;
}

button.gray-bg-darker {
	border: 0.063em solid $grayDarker;
}

button.gray-bg-darker:hover {
	background-color: darken($grayDarker, 6%);
}

button.gray-bg-darkest {
	border: 0.063em solid $grayDarkest;
}

button.gray-bg-darkest:hover {
	background-color: darken($grayDarkest, 6%);
}

button.red-bg {
	border: 0.063em solid $red;
}
button.red-bg-light {
	border: 0.063em solid $redLight;
}

button.red-bg-darkest {
	border: 0.063rem solid $redDarkest;
}

button.red-bg:hover,
.red-bg a:hover {
	background-color: $redDarker !important;
}

.dark-bg {
	background-color: $dark;
}

.dark-bg-light {
	background-color: $darkLighter;
}

.gray-bg {
	background-color: $gray !important;
}
.gray-bg-dark {
	background-color: $grayDark !important;
}

.gray-bg-darker {
	background-color: $grayDarker !important;
}

.gray-bg-darkest {
	background-color: $grayDarkest !important;
}

.gray-bg a:hover {
	color: $dark;
	background-color: $grayDark;
}

.green-bg {
	background-color: $green;
}

.green-bg-lighter {
	background-color: $greenLighter;
}

.green-bg-dark {
	background-color: $greenDarkest;
}

.green-bg-darker {
	background-color: $greenDarker;
}

.green-bg-darkest {
	background-color: $greenDarkest;
}

.green-bg-radial {
	background: radial-gradient(ellipse farthest-corner, $greenLighterRadial 0%, $greenDarkerRadial 100%);
}

.green-bg-radial a:hover {
	background-color: $greenDarker !important;
}

.limeGreen-bg {
	background-color: $limeGreen;
}

.limeGreen-blue-bg-darkest-gradient {
	background: linear-gradient(80deg, $limeGreen, $blueDarkest);
}

.lt-gray-bg {
	background-color: $light-gray !important;
}
.lt-gray-bg-darker {
	background-color: $lightGrayDarker;
}

.purple-bg {
	background-color: $purple;
}

.dark-purple-bg {
	background-color: $darkPurple;
}

.purple-bg-darkest {
	background-color: $purpleDarkest;
}

.purple-bg-light {
	background-color: $purpleLighter;
}

.purple-bg-radial {
	background: radial-gradient(ellipse farthest-corner, $purpleLighterRadial 0%, $purpleDarkerRadial 100%);
}

.orange-bg {
	background-color: $orange;
}

.orange-bg-dark {
	background-color: $orangeDarker;
}
.orange-bg-darkest {
	background-color: $orangeDarkest;
}

.pink-bg {
	background-color: $pink;
}
.pink-bg-darker {
	background-color: $pinkDarker;
}

.pink-bg-darkest {
	background-color: $pinkDarkest;
}

.red-bg {
	background-color: $red !important;
}
.red-bg-light {
	background-color: $redLight;
}

.red-bg-lighter {
	background-color: $redLighter;
}

.red-bg-darker {
	background-color: $redDarker !important;
}

.red-bg-darkest {
	background-color: $redDarkest !important;
}

.red-bg-radial {
	background: radial-gradient(ellipse farthest-corner, $redLighterRadial 0%, $redDarkerRadial 100%);
}

.transparent-bg {
	background: rgba(0, 0, 0, 0.7);
}

.turquoise-bg {
	background-color: $turquoise;
}

button.turquoise-bg:hover {
	background-color: $turquoiseDarker;
}

.white-bg {
	background-color: $white;
}

.yellow-bg {
	background-color: $yellow;
}
.yellow-bg-light {
	background-color: $yellowLight;
}
.yellow-bg-darker {
	background-color: $yellowDarker;
}

.yellow-bg-darkest {
	background-color: $yellowDarkest;
}

//Blue, purple, and red are the main product colors
.blue-gradient {
	background: linear-gradient(90deg, $blue, $turquoise);
}

.purple-gradient {
	background: linear-gradient(80deg, $purple, $blue);
}

.red-gradient {
	background: linear-gradient(80deg, $red, $yellow);
}

.blue-red-bg-gradient {
	background: linear-gradient(90deg, $blue, $red);
}

.blue-darkest-green-bg-gradient {
	background: linear-gradient(90deg, $blueDarkest, $green);
}

.green-limeGreen-bg-gradient {
	background: linear-gradient(80deg, $green, $limeGreen);
}

.yellow-orange-bg-gradient {
	background: linear-gradient(80deg, $yellow, $orange);
}

button.purple-gradient:hover {
	background: linear-gradient(80deg, $purpleDarker, $blueDarker);
}

//Lighter colors
.red-bg-light-theme {
	background-color: #ffc2c2 !important;
}

.purple-bg-light-theme {
	background-color: #ede8ff !important;
}

.blue-bg-light-theme {
	background-color: #acd7ec !important;
}

.gray-bg-light-theme {
	background-color: #a7aaa4 !important;
}

.green-bg-light-theme {
	background-color: #06d6a0 !important;
}

/*Hover Color Classes*/

.blue-bg-hover:hover {
	background-color: $blueDarkest;
}

.red-bg-hover:hover {
	background-color: $redDarkest;
}

.yellow-bg-hover:hover {
	background-color: $yellow;
}

.white-text-hover:hover {
	color: $white;
}

/*Border Color Classes*/

.white-border {
	border-color: $white;
}

.dark-border {
	border-color: $dark !important;
}

.lime-green-border {
	border-color: $limeGreen;
}

.lt-gray-border {
	border-color: $light-gray !important;
}

.lt-gray-border-darker {
	border-color: $lightGrayDarker !important;
}

.gray-border {
	border-color: $gray !important;
}

.gray-border-dark {
	border-color: $grayDark !important;
}

.gray-border-darker {
	border-color: $grayDarker !important;
}

.gray-border-darkest {
	border-color: $grayDarkest;
}

.card-gray-border {
	border-color: $card-border-gray !important;
}

.red-border {
	border-color: $red !important;
}
.red-border-light {
	border-color: $redLight !important;
}

.blue-border {
	border-color: $blue;
}

.yellow-border {
	border: 0.063rem solid $yellow;
}

.green-border {
	border-color: $green !important;
}

.purple-border {
	border-color: $purple;
}

/*Medium Outline Button Classes*/

.outline-buttons a {
	transition: all 0.1s ease-in-out;
	display: inline-block;
}

.outline-buttons a:hover {
	color: $dark;
	border-color: $dark;
}

/*Buttons*/

button {
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	padding: 0.5em 0;
	border-radius: 0.25rem;
	width: 100%;
}

button:focus {
	outline: 0;
}

/*--------------------
  Components
---------------------*/

/*Styles for Playlist Item Preview Component*/

.preview-active {
	background-color: $purpleDarkest !important;
}

/*Overlay & Disabled*/

.overlay {
	content: '';
	top: 0;
	right: 0;
	bottom: 0;
	transition: all 0.2s;
	background: rgba(0, 0, 0, 0.2);
}

.overlay:hover {
	background: rgba(0, 0, 0, 0.4);
}

.disabled {
	color: $grayDarker !important;
}

.disabled-overlay {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
}

.disabled-control {
	background-color: $grayDark;
	opacity: 0.2;
	cursor: auto;
}

.modal-overlay {
	opacity: 0.5;
	background: #23282d;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 110;
}

.setDisabledZ {
	z-index: 102;
}

.setDeletedZ {
	z-index: 103;
}

*:disabled {
	opacity: 0.2;
}

/*Toggle Switch*/

@media only screen {
	.toggleSwitch {
		display: inline-block;
		height: 18px;
		position: relative;
		overflow: visible;
		padding: 0;
		cursor: pointer;
		width: 40px;
	}
	.toggleSwitch * {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	.toggleSwitch > span {
		line-height: 20px;
		height: 20px;
		vertical-align: middle;
	}
	.toggleSwitch input:focus ~ a,
	.toggleSwitch input:focus + label {
		outline: none;
	}
	.toggleSwitch input {
		position: absolute;
		opacity: 0;
		z-index: 5;
	}
	.toggleSwitch > span {
		position: absolute;
		left: -50px;
		width: 100%;
		margin: 0;
		padding-right: 50px;
		text-align: left;
		white-space: nowrap;
	}
	.toggleSwitch > span span {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
		display: block;
		width: 50%;
		margin-left: 50px;
		text-align: left;
		font-size: 0.9em;
		width: 100%;
		left: 15%;
		top: -1px;
		opacity: 0;
	}
	.toggleSwitch a {
		position: absolute;
		right: 50%;
		top: -1px;
		z-index: 4;
		display: block;
		height: 100%;
		padding: 0;
		left: 2px;
		width: 18px;
		background-color: #fff;
		border: 1px solid #ccc;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
	}
	.toggleSwitch > span span:first-of-type {
		color: #ccc;
		opacity: 1;
		left: 45%;
	}
	.toggleSwitch > span:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 50px;
		top: -2px;
		background-color: #fafafa;
		border: 1px solid #ccc;
		border-radius: 30px;
		-webkit-transition: all 0.2s ease-out;
		-moz-transition: all 0.2s ease-out;
		transition: all 0.2s ease-out;
	}
	.toggleSwitch input:checked ~ a {
		border-color: #fff;
		left: 100%;
		margin-left: -8px;
	}
	.toggleSwitch input:checked ~ span:before {
		border-color: $limeGreen;
		box-shadow: inset 0 0 0 30px $limeGreen;
	}
	.toggleSwitch input:checked ~ span span:first-of-type {
		opacity: 0;
	}
	.toggleSwitch input:checked ~ span span:last-of-type {
		opacity: 1;
		color: #fff;
	}
	/* Switch Sizes */
	.toggleSwitch.large {
		width: 60px;
		height: 27px;
	}
	.toggleSwitch.large a {
		width: 27px;
	}
	.toggleSwitch.large > span {
		height: 29px;
		line-height: 28px;
	}
	.toggleSwitch.large input:checked ~ a {
		left: 41px;
	}
	.toggleSwitch.large > span span {
		font-size: 1.1em;
	}
	.toggleSwitch.large > span span:first-of-type {
		left: 50%;
	}
	.toggleSwitch.xlarge {
		width: 80px;
		height: 36px;
	}
	.toggleSwitch.xlarge a {
		width: 36px;
	}
	.toggleSwitch.xlarge > span {
		height: 38px;
		line-height: 37px;
	}
	.toggleSwitch.xlarge input:checked ~ a {
		left: 52px;
	}
	.toggleSwitch.xlarge > span span {
		font-size: 1.4em;
	}
	.toggleSwitch.xlarge > span span:first-of-type {
		left: 50%;
	}
}

/***************************
  TOOLTIP CUSTOM CLASSES
***************************/

.arrow-right {
	margin-left: -19px;
}

.arrow-right > .tooltip-arrow {
	left: 78% !important;
}

/*--------------------------
 Date Picker Custom Styles
---------------------------*/

.myDpMonthYearText button {
	width: initial !important;
}

/*--------------------
 Pagination Styles
---------------------*/

.pagination .ngx-pagination .current {
	background: $dark;
	color: white;
	border-radius: 0.25em;
}

.pagination .ngx-pagination a:hover {
	background-color: $grayDark;
	color: $dark;
	border-radius: 0.25em;
}

.pagination ul,
.pagination ul .pagination-previous {
	padding-left: 0 !important;
}

.pagination .ngx-pagination .ellipsis {
	width: initial;
}

/*--------------------
 Animations
---------------------*/

.slideUp {
	transition: transform 50ms 0s ease;
	animation: slideUp 0.5s ease;
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translatey(20px);
	}
	100% {
		opacity: 1;
		transform: translatey(0);
	}
}

@keyframes rotateEnter {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(20deg);
	}
}

@keyframes rotateLeave {
	0% {
		transform: rotate(20deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */

@-webkit-keyframes bounce-top {
	0% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		-webkit-transform: translateY(-24px);
		transform: translateY(-24px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	65% {
		-webkit-transform: translateY(-12px);
		transform: translateY(-12px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	82% {
		-webkit-transform: translateY(-6px);
		transform: translateY(-6px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	93% {
		-webkit-transform: translateY(-4px);
		transform: translateY(-4px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

@keyframes bounce-top {
	0% {
		-webkit-transform: translateY(-45px);
		transform: translateY(-45px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 1;
	}
	24% {
		opacity: 1;
	}
	40% {
		-webkit-transform: translateY(-24px);
		transform: translateY(-24px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	65% {
		-webkit-transform: translateY(-12px);
		transform: translateY(-12px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	82% {
		-webkit-transform: translateY(-6px);
		transform: translateY(-6px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	93% {
		-webkit-transform: translateY(-4px);
		transform: translateY(-4px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	25%,
	55%,
	75%,
	87% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
}

/**
 * ----------------------------------------
 * animation fade-in-up
 * ----------------------------------------
 */

.fadeInUp {
	animation-name: fade-in-up;
	animation-duration: 0.4s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.2, 0.3, 0.25, 0.9);
	animation-delay: 150ms;
}

.fadeInDown {
	animation-name: fade-in-down;
	animation-duration: 0.4s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.2, 0.3, 0.25, 0.9);
	animation-delay: 150ms;
}

@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translate3d(0, 12px, 0);
	}
	50% {
		// opacity: 0.9;
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

@keyframes fade-in-down {
	0% {
		opacity: 0;
		transform: translate3d(0, 0, 12px);
	}
	50% {
		// opacity: 0.9;
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

.canvas-container {
	display: inline;
	vertical-align: top;
}

/*--------------------
  Waveform Editor
---------------------*/

#track-1 > svg {
	width: 100% !important;
}

/*--------------------
  Dragula Styles
---------------------*/

.gu-mirror .row {
	background: none;
}

// /* in-flight clone */
// .gu-mirror {
//   position: fixed !important;
//   margin: 0 !important;
//   z-index: 9999 !important;
//   opacity: 0.8;
//   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
//   filter: alpha(opacity=80);
//   pointer-events: none;
// }
// /* high-performance display:none; helper */
// .gu-hide {
//   left: -9999px !important;
// }
// /* added to mirrorContainer (default = body) while dragging */
// .gu-unselectable {
//   -webkit-user-select: none !important;
//   -moz-user-select: none !important;
//   -ms-user-select: none !important;
//   user-select: none !important;
// }
// /* added to the source element while its mirror is dragged */
// .gu-transit {
//   opacity: 0.2;
//   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
//   filter: alpha(opacity=20);
// }

.ps > .ps__rail-y,
.ps > .ps__rail-y > .ps__thumb-y {
	opacity: 0.8;
}

perfect-scrollbar .ps .ps__rail-y {
	background-color: transparent !important;
}

// .ps > .ps__rail-y > .ps__thumb-y {
//     min-height: 97px;
// }

/*--------------------
  Media Queries
---------------------*/

@media screen and (max-width: 1699px) {
	:root {
		font-size: 16px !important;
	}
}

// @media screen and (min-width: 1420px) {
//      :root {
//         font-size: 16px !important;
//     }
// }

@media screen and (min-width: 1700px) {
	:root {
		font-size: 16px !important;
	}
}

@media screen and (max-width: 640px) {
	body {
		height: auto;
	}
}

@media screen and (max-width: 1199px) {
	.box {
		height: auto;
	}
}

@media screen and (min-width: 768px) {
	.showOnMobile {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.mobileMargin {
		margin-top: 24px;
	}
	.hideOnMobile {
		display: none;
	}
}

.chrome-picker {
	box-shadow: none !important;
}

.angular-editor-button {
	width: initial !important;
	border-radius: initial;
}

.angular-editor-textarea {
	background-color: $grayDark !important;
	border-radius: 0.25rem;
}

.ae-font .ae-picker-label {
	padding: initial;
}

b {
	font-family: 'Open_Sans_Semi-bold' !important;
}
